import { mdiChevronDown, mdiChevronUp, mdiEye, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  Paper,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import i18next from "i18next";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LoaderFunction, useParams } from "react-router-dom";
import {
  CreatePurchaseOrderByQuotation,
  CreatePurchaseOrderByQuotationExecution,
  Quotation,
} from "../../@types/services";
import { PurchaseOrderApiError as PurchaseOrderApiErrorType } from "../../@types/services/CreatePurchaseOrderByQuotationExecution";
import { getCreatePurchaseOrderByQuotation } from "../../services/createPurchaseOrderByQuotations";
import {
  getQuotation,
  removeCreatePurchaseOrderByQuotation,
} from "../../services/quotations";
import NotFound from "../notFound";

export const loader: LoaderFunction = async ({
  params,
}): Promise<CreatePurchaseOrderByQuotation> => {
  const createPurchaseOrderByQuotation =
    await getCreatePurchaseOrderByQuotation(
      params.createPurchaseOrderByCreatePurchaseOrderByQuotationId || "",
    );
  return createPurchaseOrderByQuotation;
};

type PurchaseOrderApiErrorProps = {
  apiError: PurchaseOrderApiErrorType;
};
const PurchaseOrderApiError: FC<PurchaseOrderApiErrorProps> = ({
  apiError,
}): ReactElement => {
  if (typeof apiError === "string") {
    return <span>api_errors</span>;
  }

  if (
    typeof apiError.error_message === "object" &&
    Object.prototype.hasOwnProperty.call(apiError.error_message, "error")
  ) {
    const { error } = apiError.error_message;
    return (
      <>
        {error.innererror.errordetails.map((errorDetail, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={index}>
            <TableCell scope="row">{errorDetail.code}</TableCell>
            <TableCell scope="row">{errorDetail.message}</TableCell>
            <TableCell scope="row">{errorDetail.propertyref}</TableCell>
            <TableCell scope="row">{errorDetail.severity}</TableCell>
            <TableCell scope="row">{errorDetail.target}</TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  return <span />;
};

type ExecutionRowProps = {
  execution: CreatePurchaseOrderByQuotationExecution;
};

const ExecutionRow: FC<ExecutionRowProps> = ({ execution }): ReactElement => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={execution.id}
        sx={{
          "& > *": { borderBottom: "unset" },
          borderLeftStyle: "solid",
          borderLeftColor: execution.success ? green["500"] : red["500"],
        }}
      >
        <TableCell>
          {Object.keys(execution.api_errors).length !== 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <Icon path={mdiChevronUp} size={1} />
              ) : (
                <Icon path={mdiChevronDown} size={1} />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <span>
            {i18next.t("dateTime", {
              val: Date.parse(execution.created_at),
              interpolation: { escapeValue: false },
              formatParams: {
                val: {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  // hour12: false,
                },
              },
            })}
          </span>
        </TableCell>

        <TableCell>
          <span>
            {i18next.t("dateTime", {
              val: Date.parse(execution.updated_at),
              interpolation: { escapeValue: false },
              formatParams: {
                val: {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  // hour12: false,
                },
              },
            })}
          </span>
        </TableCell>

        <TableCell align="center">
          {execution.completed ? (
            <span>{i18next.t("yes")}</span>
          ) : (
            <span>{i18next.t("no")}</span>
          )}
        </TableCell>

        <TableCell align="center">
          {execution.success ? (
            <span>{i18next.t("yes")}</span>
          ) : (
            <span>{i18next.t("no")}</span>
          )}
        </TableCell>

        <TableCell align="center">
          {Object.keys(execution.api_errors).length !== 0 ? (
            <span>{i18next.t("yes")}</span>
          ) : (
            <span>{i18next.t("no")}</span>
          )}
        </TableCell>
      </TableRow>
      {Object.keys(execution.api_errors).length !== 0 && (
        <TableRow key={`${execution.id}-api-errors`}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {execution.api_errors.general && (
                <Box sx={{ margin: 1 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Message</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {execution.api_errors.general.map((apiError, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={index}>
                          <TableCell>{apiError.error_message}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )}
              {execution.api_errors.purchase_orders && (
                <Box sx={{ margin: 1 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Property Ref</TableCell>
                        <TableCell>Severity</TableCell>
                        <TableCell>Target</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {execution.api_errors.purchase_orders.map((apiError) => (
                        <PurchaseOrderApiError apiError={apiError} />
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

type QuotationRowProps = {
  quotationId: number;
  createPurchaseOrderByQuotation: CreatePurchaseOrderByQuotation;
  lastExecution?: CreatePurchaseOrderByQuotationExecution;
  tableRefreshCallback: Dispatch<SetStateAction<boolean>>;
};

const QuotationRow: FC<QuotationRowProps> = ({
  quotationId,
  createPurchaseOrderByQuotation,
  lastExecution,
  tableRefreshCallback,
}): ReactElement => {
  const [quotation, setQuotation] = useState<Quotation>();
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const columnQuantity = useMemo(() => 7, []);

  const handleFetchQuotation = useCallback((id: number): void => {
    setLoading(() => true);
    getQuotation(id)
      .then((data) => {
        setQuotation(() => data);
      })
      .finally(() => {
        setLoading(() => false);
      });
  }, []);

  const handleRemoveQuotation = useCallback(
    (id: number): void => {
      setLoadingRemove(() => true);
      removeCreatePurchaseOrderByQuotation(id)
        .then(() => {
          tableRefreshCallback(() => true);
        })
        .finally(() => {
          setLoadingRemove(() => false);
          setShowConfirm(() => false);
        });
    },
    [tableRefreshCallback],
  );

  useEffect(() => {
    handleFetchQuotation(quotationId);
  }, [quotationId, handleFetchQuotation]);

  if (loading) {
    return (
      <TableRow key={quotationId}>
        {Array.from(Array(columnQuantity), () => (
          <TableCell>
            <Skeleton />
          </TableCell>
        ))}
      </TableRow>
    );
  }

  if (!quotation) {
    return (
      <TableRow key={quotationId}>
        <TableCell colSpan={columnQuantity}>Fail to load</TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow key={quotation.id}>
      <TableCell>{quotation.identifier}</TableCell>
      <TableCell>
        <span>
          {i18next.t("dateTime", {
            val: Date.parse(quotation.pr_item.requisition_date),
            interpolation: { escapeValue: false },
            formatParams: {
              val: {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                timeZone: "UTC",
                // hour: "numeric",
                // minute: "numeric",
                // second: "numeric",
                // hour12: false,
              },
            },
          })}
        </span>
      </TableCell>
      <TableCell>{quotation.pr_item.material?.matnumber}</TableCell>
      <TableCell>{quotation.vendor_winner?.fullvendorname}</TableCell>
      <TableCell>
        {quotation.purchase_order_item?.purchase_order.po_number}
      </TableCell>
      <TableCell>{quotation.purchase_order_item?.po_item_number}</TableCell>

      <TableCell align="right">
        {!quotation.purchase_order_item &&
          !createPurchaseOrderByQuotation.completed &&
          (showConfirm ? (
            <Tooltip title={i18next.t("confirm")}>
              <LoadingButton
                color="error"
                loading={loadingRemove}
                loadingPosition="center"
                onClick={() => handleRemoveQuotation(quotation.id)}
              >
                {i18next.t("confirm")}
              </LoadingButton>
            </Tooltip>
          ) : (
            <Tooltip title={i18next.t("remove")}>
              <IconButton
                onClick={() => setShowConfirm(() => true)}
                disabled={lastExecution && !lastExecution.completed}
              >
                <Icon path={mdiTrashCan} size={0.8} />
              </IconButton>
            </Tooltip>
          ))}
        <Tooltip title={i18next.t("view")}>
          <IconButton
            component={Link}
            // to={`/vbuyer/quotations/${quotation.id}`}
            href={`/vbuyer/quotations/${quotation.id}`}
            target="_blank"
          >
            <Icon path={mdiEye} size={0.8} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const ShowCreatePurchaseOrderByCreatePurchaseOrderByQuotation: FC =
  (): ReactElement => {
    // const createPurchaseOrderByQuotation = useLoaderData() as CreatePurchaseOrderByQuotation; # TODO: Future version of React Router Dom (RouterProvier)
    const { createPurchaseOrderByQuotationId } = useParams();
    const [createPurchaseOrderByQuotation, setCreatePurchaseOrderByQuotation] =
      useState<CreatePurchaseOrderByQuotation>();
    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tabOpen, setTabOpen] = useState("quotations");
    const [refresh, setRefresh] = useState(true);
    const [loadingNewExecution, setLoadingNewExecution] = useState(false);
    const [lastExecution, setLastExecution] = useState<
      undefined | CreatePurchaseOrderByQuotationExecution
    >();

    const handleFetchCreatePurchaseOrderByQuotation = useCallback(
      async (id: string) => {
        setLoading(() => true);
        await getCreatePurchaseOrderByQuotation(id)
          .then((resource) => {
            const temp = resource;
            temp.create_purchase_order_by_quotation_executions =
              resource.create_purchase_order_by_quotation_executions.sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime(),
              );
            setCreatePurchaseOrderByQuotation(() => resource);
          })
          .catch(() => {
            setNotFound(() => true);
          })
          .finally(() => {
            setRefresh(() => false);
            setLoading(() => false);
          });
      },
      [],
    );

    const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
      setTabOpen(() => newValue);
    };

    useEffect(() => {
      if (!refresh) {
        return;
      }

      if (!createPurchaseOrderByQuotationId) {
        setNotFound(() => true);
        return;
      }

      handleFetchCreatePurchaseOrderByQuotation(
        createPurchaseOrderByQuotationId,
      );
    }, [
      handleFetchCreatePurchaseOrderByQuotation,
      createPurchaseOrderByQuotationId,
      refresh,
    ]);

    useEffect(() => {
      if (
        !createPurchaseOrderByQuotation ||
        createPurchaseOrderByQuotation
          .create_purchase_order_by_quotation_executions.length <= 0
      ) {
        return;
      }

      setLastExecution(
        () =>
          createPurchaseOrderByQuotation
            .create_purchase_order_by_quotation_executions[0],
      );
    }, [createPurchaseOrderByQuotation]);

    if (notFound || !createPurchaseOrderByQuotationId) {
      return <NotFound />;
    }

    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 2,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <h1>{i18next.t("createPurchaseOrderByQuotation.title")}</h1>

        {createPurchaseOrderByQuotation &&
          !createPurchaseOrderByQuotation.completed &&
          (loadingNewExecution ? (
            <Button variant="outlined" color="info">
              <CircularProgress
                color="success"
                size={16}
                sx={{ marginRight: "5px" }}
              />
              <span>
                {i18next.t("createPurchaseOrderByQuotation.runAgain")}
              </span>
            </Button>
          ) : (
            <Button
              variant="outlined"
              type="button"
              href={`/vbuyer/api/v2/create_purchase_order_by_quotations/${createPurchaseOrderByQuotation.id}/generate_purchase_order`}
              onClick={() => setLoadingNewExecution(() => true)}
              disabled={
                lastExecution &&
                (!lastExecution.completed ||
                  !createPurchaseOrderByQuotation.can_retry)
              }
            >
              {i18next.t("createPurchaseOrderByQuotation.runAgain")}
            </Button>
          ))}

        <TabContext value={tabOpen}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabOpen} onChange={handleTabChange}>
              <Tab
                label={i18next.t("createPurchaseOrderByQuotation.quotations")}
                value="quotations"
              />
              <Tab
                label={i18next.t("createPurchaseOrderByQuotation.executions")}
                value="executions"
              />
            </Tabs>
          </Box>

          <TabPanel value="quotations">
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        {i18next.t("quotation.identifier")}
                      </TableCell>
                      <TableCell align="left">
                        {i18next.t("quotation.requisitionDate")}
                      </TableCell>
                      <TableCell align="left">
                        {i18next.t("quotation.material")}
                      </TableCell>
                      <TableCell align="left">
                        {i18next.t("quotation.vendor")}
                      </TableCell>
                      <TableCell align="left">Purchase Order</TableCell>
                      <TableCell align="left">Purchase Order Item</TableCell>
                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && (
                      <TableRow key={1}>
                        {Array.from(Array(7), () => (
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                    {!loading &&
                      createPurchaseOrderByQuotation &&
                      createPurchaseOrderByQuotation.quotations?.length > 0 &&
                      createPurchaseOrderByQuotation.quotations.map(
                        (quotation) => (
                          <QuotationRow
                            quotationId={quotation.id}
                            createPurchaseOrderByQuotation={
                              createPurchaseOrderByQuotation
                            }
                            lastExecution={lastExecution}
                            tableRefreshCallback={setRefresh}
                          />
                        ),
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>

          <TabPanel value="executions">
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell align="left">
                        {i18next.t(
                          "createPurchaseOrderByQuotationExecution.createdAt",
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {i18next.t(
                          "createPurchaseOrderByQuotationExecution.updatedAt",
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {i18next.t(
                          "createPurchaseOrderByQuotationExecution.completed",
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {i18next.t(
                          "createPurchaseOrderByQuotationExecution.success",
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {i18next.t(
                          "createPurchaseOrderByQuotationExecution.hasErrors",
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && (
                      <TableRow key={1}>
                        {Array.from(Array(6), () => (
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                    {!loading &&
                      createPurchaseOrderByQuotation &&
                      createPurchaseOrderByQuotation
                        .create_purchase_order_by_quotation_executions?.length >
                        0 &&
                      createPurchaseOrderByQuotation.create_purchase_order_by_quotation_executions.map(
                        (execution) => (
                          <ExecutionRow
                            key={execution.id}
                            execution={execution}
                          />
                        ),
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    );
  };

export default ShowCreatePurchaseOrderByCreatePurchaseOrderByQuotation;
