import { Box, Toolbar, Typography, Button } from "@mui/material";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ListMaterials from "./components/molecules/ListMaterials";
import useAuth from "./hooks/useAuth";
import api from "./services/api";

const MM_REQUESTS_PATH = "/mm_requests";

const getApiData = () => {
  return api({
    method: "get",
    url: MM_REQUESTS_PATH,
    baseURL: "/vbuyer/api/v1",
  }).then((response) => response.data);
};

const Main: FC = (): ReactElement => {
  const [materials, setMaterials] = useState([]);
  const { user } = useAuth();

  const retrieveMmRequests = useCallback(() => {
    getApiData().then(({ results }) => {
      setMaterials(results);
    });
  }, []);

  useEffect(() => {
    if (user) {
      retrieveMmRequests();
    }
  }, [user, retrieveMmRequests]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        justifyContent: "center",
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 1,
        gridTemplateRows: "auto",
        gridTemplateAreas: `"header"
        "main "`,
      }}
    >
      <Toolbar
        sx={{
          gridArea: "header",
        }}
      />

      <Button
        color="energyRed"
        variant="contained"
        size="large"
        sx={{ width: "100%", "&:hover": { backgroundColor: "#de002e" } }}
        component={Link}
        to="/react/demo"
      >
        Demo
      </Button>

      <Typography
        sx={{ justifyContent: "center", textAlign: "center" }}
        paragraph
      >
        Materials
      </Typography>

      <ListMaterials materials={materials} />
    </Box>
  );
};

export default Main;
