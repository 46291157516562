import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import { FC, ReactElement, Suspense, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import LeftMenuInterface from "./@types/components/organisms/LeftMenu";
import { CircularIndeterminate } from "./components/molecules";
import CookieConsent from "./components/molecules/CookieConsent";
import LeftMenu from "./components/organisms/LeftMenu";
import TopMenu from "./components/organisms/TopMenu";
import ThemeModeContext from "./contexts/ThemeMode";
import useAuth from "./hooks/useAuth";
import i18next from "./i18n"; // Importe sua configuração do i18next
import AppProvider from "./providers/AppProvider";
import ThemeModeProvider from "./providers/ThemeMode";
import AppRoutes from "./routes";

const MainApp: FC<{ leftMenu: LeftMenuInterface[] }> = ({
  leftMenu,
}): ReactElement => {
  const { user, loading } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [leftMenuOpen, setLeftMenuOpen] = useState(true);

  if (loading) {
    enqueueSnackbar("Loading user");
    return <p>Loading user</p>;
  }

  return (
    <>
      {user && <TopMenu setLeftMenuOpen={setLeftMenuOpen} />}
      <Box sx={{ display: "flex" }}>
        {user && (
          <LeftMenu
            options={leftMenu}
            leftMenuOpen={leftMenuOpen}
            setLeftMenuOpen={setLeftMenuOpen}
          />
        )}
        <AppRoutes />
      </Box>
      <CookieConsent />
    </>
  );
};

const queryClient = new QueryClient();

const App: FC<{ leftMenu: LeftMenuInterface[] }> = ({
  leftMenu,
}): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeModeProvider>
        <ThemeModeContext.Consumer>
          {({ theme }) => (
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider>
                <Suspense fallback={<CircularIndeterminate />}>
                  <BrowserRouter>
                    <AppProvider>
                      <I18nextProvider i18n={i18next}>
                        <MainApp leftMenu={leftMenu} />
                      </I18nextProvider>
                    </AppProvider>
                  </BrowserRouter>
                </Suspense>
              </SnackbarProvider>
            </ThemeProvider>
          )}
        </ThemeModeContext.Consumer>
      </ThemeModeProvider>
    </QueryClientProvider>
  );
};

export default App;
