import { mdiAccountDetails } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Button,
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import i18next from "i18next";
import { FC, ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import ServiceQuotation from "../../../@types/services/ServiceQuotation";

interface ListServiceQuotationProps {
  serviceQuotations: ServiceQuotation[];
  loading: boolean;
}
interface ExtendedServiceQuotation extends ServiceQuotation {
  [key: string]: unknown;
}

const ListServiceQuotations: FC<ListServiceQuotationProps> = ({
  serviceQuotations,
  loading,
}): ReactElement => {
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState("asc");

  const handleSort = (property: string): void => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const sortedServiceQuotations = serviceQuotations.slice().sort((a, b) => {
    const aValue = (a as ExtendedServiceQuotation)[orderBy] as
      | string
      | number
      | boolean;
    const bValue = (b as ExtendedServiceQuotation)[orderBy] as
      | string
      | number
      | boolean;

    if (order === "asc") {
      return aValue < bValue ? -1 : 1;
    }
    return aValue > bValue ? -1 : 1;
  });

  return (
    <Card
      sx={{
        p: 2,
        borderRadius: "5px",
        border: "5px",
        width: "100%",
        height: "100%",
      }}
    >
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: `20%` }} align="left">
                <TableSortLabel
                  active={orderBy === "identifier"}
                  direction={order === "asc" ? "asc" : "desc"}
                  onClick={() => handleSort("identifier")}
                >
                  {i18next.t("serviceQuotation.identifier")}
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ width: `20%` }} align="left">
                {i18next.t("serviceQuotation.status")}
              </TableCell>
              <TableCell sx={{ width: `25%` }} align="left">
                {i18next.t("serviceQuotation.shortDescription")}
              </TableCell>
              <TableCell sx={{ width: `10%` }} align="left">
                {i18next.t("serviceQuotation.prNumber")}
              </TableCell>
              <TableCell sx={{ width: `5%` }} align="left">
                {i18next.t("serviceQuotation.quantityOfProposals")}
              </TableCell>
              <TableCell sx={{ width: `5%` }} align="left">
                {i18next.t("serviceQuotation.plantNumber")}
              </TableCell>
              <TableCell sx={{ width: `5%` }} align="left">
                {i18next.t("serviceQuotation.purchaseGroup")}
              </TableCell>
              <TableCell sx={{ width: `5%` }} align="left">
                <TableSortLabel
                  active={orderBy === "created_at"}
                  direction={order === "asc" ? "asc" : "desc"}
                  onClick={() => handleSort("created_at")}
                >
                  {i18next.t("createdAt")}
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ width: `5%` }} align="center">
                #
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow key={1}>
                {Array.from(Array(6), () => (
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            )}
            {!loading &&
              sortedServiceQuotations?.length > 0 &&
              sortedServiceQuotations.map(
                (serviceQuotation: ServiceQuotation) => (
                  <TableRow
                    key={serviceQuotation.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{ width: `20%` }}
                    >{`${serviceQuotation.identifier}`}</TableCell>

                    <TableCell
                      sx={{ width: `20%` }}
                    >{`${serviceQuotation?.status?.title}`}</TableCell>
                    <TableCell
                      sx={{ width: `25%` }}
                    >{`${serviceQuotation.short_description}`}</TableCell>
                    <TableCell
                      sx={{ width: `10%` }}
                    >{`${serviceQuotation.pr_item.pr_number}`}</TableCell>
                    <TableCell
                      sx={{ width: `5%`, textAlign: "center" }}
                    >{`${serviceQuotation.proposals.length || 0}`}</TableCell>
                    <TableCell
                      sx={{ width: `5%` }}
                    >{`${serviceQuotation.pr_item.plant.plant_number}`}</TableCell>
                    <TableCell
                      sx={{ width: `5%` }}
                    >{`${serviceQuotation.pr_item.purchase_group.name}`}</TableCell>
                    <TableCell sx={{ width: `5%` }}>
                      {i18next.t("dateTime", {
                        val: Date.parse(serviceQuotation.created_at),
                        interpolation: { escapeValue: false },
                        formatParams: {
                          val: {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            // hour12: false,
                          },
                        },
                      })}
                    </TableCell>
                    <TableCell sx={{ width: `5%` }} align="center">
                      <Button
                        style={{ backgroundColor: "white", color: "black" }}
                        component={Link}
                        to={`/vbuyer/service_quotations/${serviceQuotation.id}`}
                      >
                        <Icon size={1} path={mdiAccountDetails} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ListServiceQuotations;
