/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import { TextField, Unstable_Grid2 } from "@mui/material";
import { FC } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

interface TextFieldProps {
  label: string;
  name: FieldPath<FieldValues>;
  // onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  type: "number" | "string" | "text";
  control: Control<FieldValues>;
  size?: "small" | "medium";
}

const FilterTextField: FC<TextFieldProps> = ({
  label,
  name,
  type = "text",
  control,
  size = "small",
}) => {
  return (
    <Unstable_Grid2 xs={6}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={label}
            type={type}
            value={field.value === undefined ? "" : field.value}
            fullWidth
            size={size}
          />
        )}
      />
    </Unstable_Grid2>
  );
};

export default FilterTextField;
