import { mdiEye } from "@mdi/js";
import Icon from "@mdi/react";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  IconButton,
  Paper,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import i18next from "i18next";
import {
  ChangeEvent,
  FC,
  ReactElement,
  MouseEvent as ReactMouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { CreatePurchaseOrderByQuotation } from "../../@types/services";
import {
  getCreatePurchaseOrderByQuotation,
  listCreatePurchaseOrderByQuotations,
} from "../../services/createPurchaseOrderByQuotations";

type CreatePurchaseOrderByQuotationRowProps = {
  createPurchaseOrderByQuotationId: string;
};

const CreatePurchaseOrderByQuotationRow: FC<
  CreatePurchaseOrderByQuotationRowProps
> = ({ createPurchaseOrderByQuotationId }): ReactElement => {
  const [data, setData] = useState<CreatePurchaseOrderByQuotation>();
  const [loading, setLoading] = useState(true);

  const columnQuantity = useMemo(() => 4, []);

  const handleFetch = useCallback((id: string): void => {
    setLoading(() => true);
    getCreatePurchaseOrderByQuotation(id)
      .then((response) => {
        setData(() => response);
      })
      .finally(() => {
        setLoading(() => false);
      });
  }, []);

  useEffect(() => {
    handleFetch(createPurchaseOrderByQuotationId);
  }, [createPurchaseOrderByQuotationId, handleFetch]);

  if (loading) {
    return (
      <TableRow key={createPurchaseOrderByQuotationId}>
        {Array.from(Array(columnQuantity), () => (
          <TableCell>
            <Skeleton />
          </TableCell>
        ))}
      </TableRow>
    );
  }

  if (!data) {
    return (
      <TableRow key={createPurchaseOrderByQuotationId}>
        <TableCell colSpan={columnQuantity}>Fail to load</TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow key={data.id}>
      <TableCell>
        <span>
          {i18next.t("dateTime", {
            val: Date.parse(data.created_at),
            interpolation: { escapeValue: false },
            formatParams: {
              val: {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                // hour12: false,
              },
            },
          })}
        </span>
      </TableCell>

      <TableCell>
        <span>
          {i18next.t("dateTime", {
            val: Date.parse(data.updated_at),
            interpolation: { escapeValue: false },
            formatParams: {
              val: {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                // hour12: false,
              },
            },
          })}
        </span>
      </TableCell>

      <TableCell align="center">
        {data.completed ? (
          <span>{i18next.t("yes")}</span>
        ) : (
          <span>{i18next.t("no")}</span>
        )}
      </TableCell>

      <TableCell align="center">
        <Tooltip title={i18next.t("view")}>
          <IconButton component={Link} to={data.id}>
            <Icon path={mdiEye} size={0.8} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

type CreatePurchaseOrderByQuotationTableProps = {
  completed: boolean;
};

const CreatePurchaseOrderByQuotationTable: FC<
  CreatePurchaseOrderByQuotationTableProps
> = ({ completed }): ReactElement => {
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [createPurchaseOrderByQuotations, setCreatePurchaseOrderByQuotations] =
    useState<CreatePurchaseOrderByQuotation[]>([]);
  const [loading, setLoading] = useState(true);

  const handleFetch = useCallback(
    async (page: number) => {
      setLoading(() => true);
      await listCreatePurchaseOrderByQuotations({
        page,
        itemsPerPage: rowsPerPage,
        order: "desc",
        filterCompleted: completed,
      })
        .then((createPurchaseOrderByQuotation) => {
          setCreatePurchaseOrderByQuotations(
            () => createPurchaseOrderByQuotation.results || [],
          );
          setTotalRows(() => createPurchaseOrderByQuotation.total_items || 0);
        })
        .finally(() => {
          setLoading(() => false);
        });
    },
    [completed, rowsPerPage],
  );

  const handleChangePage = (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    value: number,
  ): void => {
    setCurrentPage(() => value + 1);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setRowsPerPage(() => parseInt(event.target.value, 10));
    setCurrentPage(() => 1);
  };

  useEffect(() => {
    handleFetch(currentPage);
  }, [currentPage, handleFetch]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              {i18next.t("createPurchaseOrderByQuotation.createdAt")}
            </TableCell>
            <TableCell align="left">
              {i18next.t("createPurchaseOrderByQuotation.updatedAt")}
            </TableCell>
            <TableCell align="center">
              {i18next.t("createPurchaseOrderByQuotation.completed")}
            </TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow key={1}>
              {Array.from(Array(4), () => (
                <TableCell>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          )}
          {!loading &&
            createPurchaseOrderByQuotations &&
            createPurchaseOrderByQuotations.map(
              (createPurchaseOrderByQuotation) => (
                <CreatePurchaseOrderByQuotationRow
                  createPurchaseOrderByQuotationId={
                    createPurchaseOrderByQuotation.id
                  }
                />
              ),
            )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={currentPage - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const CreatePurchaseOrderByQuotations: FC = (): ReactElement => {
  const [tabOpen, setTabOpen] = useState("notCompleted");

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setTabOpen(() => newValue);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        px: 2,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <h1>{i18next.t("createPurchaseOrderByQuotation.title")}</h1>

      <TabContext value={tabOpen}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabOpen} onChange={handleTabChange}>
            <Tab
              label={i18next.t("createPurchaseOrderByQuotation.notCompleted")}
              value="notCompleted"
            />
            <Tab
              label={i18next.t("createPurchaseOrderByQuotation.completed")}
              value="completed"
            />
          </Tabs>
        </Box>

        <TabPanel value="notCompleted">
          <CreatePurchaseOrderByQuotationTable completed={false} />
        </TabPanel>

        <TabPanel value="completed">
          <CreatePurchaseOrderByQuotationTable completed />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default CreatePurchaseOrderByQuotations;
