import { mdiCheckboxMarkedCircle, mdiClock, mdiDownload } from "@mdi/js";
import Icon from "@mdi/react";
import { LoadingButton } from "@mui/lab";
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Container,
  Grid,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import i18next from "i18next";
import { enqueueSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ServiceNegotiation from "../../@types/services/ServiceNegotiation";
import ServiceQuotation from "../../@types/services/ServiceQuotation";
import ServiceQuotationApproval from "../../@types/services/ServiceQuotationApproval";
import BasicBar from "../../components/molecules/BasicBar";
import {
  ServiceNegotiationFilter,
  fetchServiceNegotiations,
} from "../../services/serviceNegotiations";
import {
  getServiceQuotationApproval,
  updateServiceQuotationApproval,
} from "../../services/serviceQuotationApprovals";

type FormServiceQuotationApprovalProps = {
  serviceQuotationApproval: ServiceQuotationApproval;
};

type ServiceBidWinnerJustificationProps = {
  serviceQuotation: ServiceQuotation;
};

const ServiceBidWinnerJustification: FC<ServiceBidWinnerJustificationProps> = ({
  serviceQuotation,
}) => {
  return (
    <Card sx={{ marginTop: 1 }} square>
      <CardContent sx={{ p: 3 }}>
        <Grid container sx={{ marginBottom: 2 }} spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2">
              {i18next.t("serviceQuotationApproval.forms.field.justification")}
            </Typography>
            <Typography variant="subtitle2">
              {serviceQuotation.last_service_bid_winner.justification}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2">
              {i18next.t("serviceNegotiation.dialogWinner.typeOfRfq")}
            </Typography>
            <Typography variant="subtitle2">
              {
                serviceQuotation.last_service_bid_winner.purchase_order_type
                  .name
              }
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="body2">
            {i18next.t("serviceQuotationApproval.awardDocuments")}
          </Typography>
          <Typography variant="subtitle2">
            <Card sx={{ margin: 2, minWidth: 275 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ minWidth: 170 }}>
                      {i18next.t("filename")}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: 170 }}>
                      #
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceQuotation.last_service_bid_winner.award_documents
                    .length > 0 &&
                    serviceQuotation.last_service_bid_winner.award_documents.map(
                      (document) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={document.id}
                        >
                          <TableCell align="center">
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                            >
                              {document.filename}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              sx={{ width: 200 }}
                              href={`/vbuyer/api/v2/service_bid_winners/${serviceQuotation.last_service_bid_winner.id}/get_award_document?award_document_id=${document.id}`}
                              download={document.filename}
                              target="_blank"
                            >
                              File <Icon path={mdiDownload} size={0.8} />
                            </Button>
                          </TableCell>
                          {/* <TableCell align="center">
                                <Switch {...label} />
                              </TableCell> */}
                        </TableRow>
                      ),
                    )}
                </TableBody>
              </Table>
            </Card>
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

const FormServiceQuotationApproval: FC<FormServiceQuotationApprovalProps> = ({
  serviceQuotationApproval,
}) => {
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [serviceNegotiations, setServiceNegotiations] =
    useState<ServiceNegotiation[]>();

  const navigate = useNavigate();

  const initialValues: ServiceQuotationApproval = {
    id: 0,
    decision: "",
    justification: "",
    service_quotation: serviceQuotationApproval.service_quotation,
  };

  const handleFetchServiceNegotiations = useCallback(
    async (filters?: ServiceNegotiationFilter) => {
      setLoading(() => true);
      await fetchServiceNegotiations({
        filters,
      })
        .then((sq) => {
          setServiceNegotiations(() => sq.results || []);
        })
        .catch(() => {
          setNotFound(() => true);
        })
        .finally(() => {
          setLoading(() => false);
        });
    },
    [],
  );

  useEffect(() => {
    if (!serviceQuotationApproval) {
      setNotFound(() => true);
      return;
    }

    handleFetchServiceNegotiations({
      service_quotation_id: serviceQuotationApproval.service_quotation.id,
    });
  }, [handleFetchServiceNegotiations, serviceQuotationApproval]);

  return (
    <TableContainer sx={{ marginTop: 1 }} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> {i18next.t("serviceQuotation.vendor")} </TableCell>
            <TableCell align="center">
              {" "}
              {i18next.t("serviceNegotiation.status")}{" "}
            </TableCell>
            <TableCell align="center">
              {" "}
              {i18next.t("serviceNegotiation.value")}{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {serviceNegotiations &&
            serviceNegotiations
              .sort((a, b) => {
                const priceA = a.proposal?.price ?? null;
                const priceB = b.proposal?.price ?? null;

                if (priceA === null && priceB === null) return 0;
                else if (priceA === null) return 1;
                else if (priceB === null) return -1;
                return priceA - priceB;
              })
              .map((serviceNegotiation: ServiceNegotiation) => (
                <TableRow
                  key={serviceNegotiation.id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell>
                    {serviceNegotiation.vendor.fullvendorname}
                  </TableCell>
                  <TableCell align="center">
                    {serviceNegotiation.vendor.fullvendorname ==
                    serviceQuotationApproval.service_quotation.vendor_winner
                      ?.fullvendorname ? (
                      <Icon
                        path={mdiCheckboxMarkedCircle}
                        size={1}
                        color="green"
                      />
                    ) : (
                      <Icon path={mdiClock} size={1} color="slateBlue" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {serviceNegotiation?.proposal?.currency.unit || ""}{" "}
                    {serviceNegotiation?.proposal?.price || "-"}
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <hr />

      {serviceQuotationApproval.service_quotation.status.name ==
        "Waiting Approval" && (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            setLoading(true);

            updateServiceQuotationApproval(serviceQuotationApproval.id, values)
              .then(() => {
                navigate(
                  `/vbuyer/service_quotations/${serviceQuotationApproval?.service_quotation.id}`,
                );
                if (values.decision == "approve") {
                  enqueueSnackbar(
                    i18next.t("enqueueSnackbar.proposalApproved"),
                  );
                } else if (values.decision == "refuse") {
                  enqueueSnackbar(i18next.t("enqueueSnackbar.proposalReject"));
                }
              })
              .catch((error) => {
                enqueueSnackbar(error.message);
              });
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue }) => (
            <Form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "3px",
                padding: "20px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  multiline
                  rows={3}
                  label={i18next.t(
                    "serviceQuotationApproval.forms.field.justification",
                  )}
                  id="filled-size-small"
                  name="justification"
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  required
                />
                <TextField
                  type="hidden"
                  name="decision"
                  size="small"
                  onChange={handleChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingButton
                  type="submit"
                  style={{ marginTop: "8px", justifyContent: "center" }}
                  color="energyRed"
                  variant="contained"
                  loading={loading}
                  loadingPosition="center"
                  onClick={() => setFieldValue("decision", "reject")}
                >
                  {i18next.t("serviceQuotationApproval.forms.button.reject")}
                </LoadingButton>
                <LoadingButton
                  type="submit"
                  style={{ marginTop: "8px", justifyContent: "center" }}
                  color="success"
                  variant="contained"
                  loading={loading}
                  loadingPosition="center"
                  onClick={() => setFieldValue("decision", "approve")}
                >
                  {i18next.t("serviceQuotationApproval.forms.button.approve")}
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </TableContainer>
  );
};

const ShowServiceQuotationApproval: FC = () => {
  const { serviceQuotationApprovalId } = useParams();
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [serviceQuotationApproval, setServiceQuotationApproval] =
    useState<ServiceQuotationApproval>();

  const handleFetchServiceQuotationApproval = useCallback(
    async (id: number): Promise<void> => {
      await getServiceQuotationApproval(id)
        .then((resource) => {
          setServiceQuotationApproval(resource);
        })
        .catch(() => {
          setNotFound(() => true);
        })
        .finally(() => {
          setLoading(() => false);
        });
    },
    [],
  );

  useEffect(() => {
    if (!serviceQuotationApprovalId) {
      setNotFound(() => true);
      return;
    }

    handleFetchServiceQuotationApproval(
      parseInt(serviceQuotationApprovalId, 10),
    );
  }, [handleFetchServiceQuotationApproval]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        px: 2,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          padding: "0px 20px",
          display: "flex",
        }}
      >
        <div>
          <h2>{i18next.t("serviceQuotation.title")}</h2>
          <h3 style={{ marginTop: "-10px" }}>
            <Link
              to={`/vbuyer/service_quotations/${serviceQuotationApproval?.service_quotation?.id}`}
            >
              #{serviceQuotationApproval?.service_quotation?.identifier}
            </Link>
          </h3>
        </div>
      </Box>

      <Container sx={{ marginBottom: 4 }}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar color="gray" position="static">
            <Toolbar variant="dense">
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {i18next.t("details")}
              </Typography>
              <ButtonGroup
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #B4B3B3",
                  backgroundColor: "#fff",
                  borderRadius: "15px",
                }}
                size="small"
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#757575",
                    padding: "6px",
                  }}
                >
                  {
                    serviceQuotationApproval?.service_quotation?.scope_of_work
                      ?.filename
                  }
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginLeft: "8px",
                    borderRadius: "15px",
                  }}
                  href={`/vbuyer/api/v2/service_quotations/${serviceQuotationApproval?.service_quotation.id}/get_scope_of_work`}
                  download={
                    serviceQuotationApproval?.service_quotation?.scope_of_work
                      ?.filename
                  }
                  target="_blank"
                >
                  {i18next.t("serviceNegotiation.scopeOfWork")}
                  <span style={{ margin: "4px 0px 0px 0px" }}>
                    <Icon path={mdiDownload} size={0.8} />
                  </span>
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>
        </Box>
        <Card sx={{ marginTop: 1 }} square>
          <CardContent sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.prNumber")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotationApproval?.service_quotation?.pr_item
                    ?.pr_number || ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.itemNumber")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotationApproval?.service_quotation?.pr_item
                    ?.item_number || ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceQuotation.requisitionDate")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotationApproval?.service_quotation?.pr_item
                    ?.requisition_date
                    ? i18next.t("dateTime", {
                        val: Date.parse(
                          serviceQuotationApproval?.service_quotation?.pr_item
                            .requisition_date,
                        ),
                        interpolation: { escapeValue: false },
                        formatParams: {
                          val: {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            timeZone: "UTC",
                          },
                        },
                      })
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.shortDescription")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotationApproval?.service_quotation
                    ?.short_description || ""}
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.plantNumber")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotationApproval?.service_quotation?.pr_item?.plant
                    ?.plant_number || ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography variant="body2">
                  {i18next.t("serviceNegotiation.quantity")}
                </Typography>
                <Typography variant="subtitle2">
                  {serviceQuotationApproval?.service_quotation?.pr_item
                    ?.quantity || ""}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <OutlinedInput
                  id="description"
                  multiline
                  rows={5} // Número de linhas que o TextArea terá
                  placeholder={i18next.t("serviceNegotiation.description")}
                  value={
                    serviceQuotationApproval?.service_quotation?.long_text || ""
                  }
                  sx={{ fontSize: "0.9em" }}
                  disabled
                  fullWidth
                  style={{
                    marginTop: "16px",
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>

      <Container sx={{ marginBottom: 4 }}>
        <BasicBar title={i18next.t("serviceQuotationApproval.award_infos")} />
        {serviceQuotationApproval?.service_quotation && (
          <ServiceBidWinnerJustification
            serviceQuotation={serviceQuotationApproval?.service_quotation}
          />
        )}
      </Container>

      <Container>
        <BasicBar title={i18next.t("proposal.proposals")} />

        {serviceQuotationApproval && (
          <FormServiceQuotationApproval
            serviceQuotationApproval={serviceQuotationApproval}
          />
        )}
      </Container>
    </Box>
  );
};

export default ShowServiceQuotationApproval;
