import ServiceNegotiation from "../@types/services/ServiceNegotiation";
import ServiceQuotation from "../@types/services/ServiceQuotation";
import Vendor from "../@types/services/Vendor";
import api from "./api";

export type ServiceQuotationFilter = {
  identifier?: number;
  status_name?: string[];
  purchase_group_ids?: number[];
  plant_ids?: number[];
};

interface ServiceQuotationsFetch {
  page?: number;
  itemsPerPage?: number;
  filters?: ServiceQuotationFilter;
}

interface ServiceQuotationsResponse {
  total_items: number;
  results: ServiceQuotation[] | null;
}

export interface DocumentProps {
  url: string;
  filename: string;
  mail_user: string;
  created_at: Date;
}

export interface ScopeOfWorkProps {
  url: string;
  filename: string;
}
interface ListServiceQuotationsIssuePurchaseOrderParams {
  page?: number;
  itemsPerPage?: number;
  order?: "asc" | "desc";
  valid?: boolean;
}

interface ServiceQuotationResponse {
  total_items: number;
  results: ServiceQuotation[] | null;
}

export interface activityProps {
  id: number;
  act_action: string;
  updated_by: string;
  activity: string;
  act_tstamp: string;
}

export const fetchServiceQuotations = async ({
  page = 1,
  itemsPerPage = 25,
  filters,
}: ServiceQuotationsFetch): Promise<ServiceQuotationsResponse> => {
  const response = await api.get<ServiceQuotationsResponse>(
    "/service_quotations",
    {
      baseURL: "/vbuyer/api/v2",
      params: {
        page,
        items_per_page: itemsPerPage,
        ...filters,
      },
    },
  );

  return response.data;
};

export const getServiceQuotation = async (
  id: number,
): Promise<ServiceQuotation> => {
  const response = await api.get<ServiceQuotation>(
    `/service_quotations/${id}`,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};

export const removeCreatePurchaseOrderByServiceQuotation = async (
  id: number,
): Promise<void> => {
  const response = await api.delete<void>(
    `/service_quotations/${id}/remove_create_purchase_order_by_quotation`,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};

export const listServiceQuotationsIssuePurchaseOrder = async ({
  page = 1,
  itemsPerPage = 25,
  order = "asc",
  valid = true,
}: ListServiceQuotationsIssuePurchaseOrderParams): Promise<ServiceQuotationResponse> => {
  const response = await api.get<ServiceQuotationResponse>(
    "/service_quotations/issue_service_purchase_order",
    {
      baseURL: "/vbuyer/api/v2",
      params: {
        page,
        items_per_page: itemsPerPage,
        order,
        valid,
      },
    },
  );

  return response.data;
};

export const inviteVendors = async (
  id: number,
  vendors: Vendor[],
): Promise<ServiceNegotiation> => {
  const response = await api.post<ServiceNegotiation>(
    `/service_quotations/${id}/invite_vendors`,
    { vendors },
    {
      baseURL: `/vbuyer/api/v2`,
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return response.data;
};

export const addScopeOfWork = async (
  id: number,
  attach: unknown,
): Promise<ServiceQuotation> => {
  const response = await api.postForm<ServiceQuotation>(
    `/service_quotations/${id}/add_scope_of_work`,
    attach,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};

export const addDocument = async (
  id: number,
  attach: unknown,
): Promise<ServiceQuotation> => {
  const response = await api.postForm<ServiceQuotation>(
    `/service_quotations/${id}/add_document`,
    attach,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );

  return response.data;
};

export const fetchDocuments = async (id: number): Promise<DocumentProps[]> => {
  const response = await api.get<DocumentProps[]>(
    "service_quotations/get_documents",
    {
      baseURL: "/vbuyer/api/v2",
      params: {
        id,
      },
    },
  );

  return response.data;
};

export const fetchActivityLogsByQuotation = async (
  id: number,
): Promise<activityProps[]> => {
  const response = await api.get(
    "/service_quotations/activity_logs_by_quotation",
    {
      baseURL: "/vbuyer/api/v2",
      params: {
        id,
      },
    },
  );

  return response.data;
};
