import { mdiChevronDown, mdiChevronUp, mdiEye, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  Paper,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import i18next from "i18next";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LoaderFunction, useParams } from "react-router-dom";
import CreatePurchaseOrderByServiceQuotation from "../../@types/services/CreatePurchaseOrderByServiceQuotation";
import CreatePurchaseOrderByServiceQuotationExecution, {
  PurchaseOrderApiError as PurchaseOrderApiErrorType,
} from "../../@types/services/CreatePurchaseOrderByServiceQuotationExecution";
import ServiceQuotation from "../../@types/services/ServiceQuotation";
import { getCreatePurchaseOrderByServiceQuotation } from "../../services/createPurchaseOrderByServiceQuotations";
import {
  getServiceQuotation,
  removeCreatePurchaseOrderByServiceQuotation,
} from "../../services/serviceQuotations";
import NotFound from "../notFound";

export const loader: LoaderFunction = async ({
  params,
}): Promise<CreatePurchaseOrderByServiceQuotation> => {
  const createPurchaseOrderByServiceQuotation =
    await getCreatePurchaseOrderByServiceQuotation(
      params.createPurchaseOrderByCreatePurchaseOrderByServiceQuotationId || "",
    );
  return createPurchaseOrderByServiceQuotation;
};

type PurchaseOrderApiErrorProps = {
  apiError: PurchaseOrderApiErrorType;
};
const PurchaseOrderApiError: FC<PurchaseOrderApiErrorProps> = ({
  apiError,
}): ReactElement => {
  if (typeof apiError === "string") {
    return <span>api_errors</span>;
  }

  if (
    typeof apiError.error_message === "object" &&
    Object.prototype.hasOwnProperty.call(apiError.error_message, "error")
  ) {
    const { error } = apiError.error_message;
    return (
      <>
        {error.innererror.errordetails.map((errorDetail, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={index}>
            <TableCell scope="row">{errorDetail.code}</TableCell>
            <TableCell scope="row">{errorDetail.message}</TableCell>
            <TableCell scope="row">{errorDetail.propertyref}</TableCell>
            <TableCell scope="row">{errorDetail.severity}</TableCell>
            <TableCell scope="row">{errorDetail.target}</TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  return <span />;
};

type ExecutionRowProps = {
  execution: CreatePurchaseOrderByServiceQuotationExecution;
};

const ExecutionRow: FC<ExecutionRowProps> = ({ execution }): ReactElement => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={execution.id}
        sx={{
          "& > *": { borderBottom: "unset" },
          borderLeftStyle: "solid",
          borderLeftColor: execution.success ? green["500"] : red["500"],
        }}
      >
        <TableCell>
          {Object.keys(execution.api_errors).length !== 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <Icon path={mdiChevronUp} size={1} />
              ) : (
                <Icon path={mdiChevronDown} size={1} />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <span>
            {i18next.t("dateTime", {
              val: Date.parse(execution.created_at),
              interpolation: { escapeValue: false },
              formatParams: {
                val: {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  // hour12: false,
                },
              },
            })}
          </span>
        </TableCell>

        <TableCell>
          <span>
            {i18next.t("dateTime", {
              val: Date.parse(execution.updated_at),
              interpolation: { escapeValue: false },
              formatParams: {
                val: {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  // hour12: false,
                },
              },
            })}
          </span>
        </TableCell>

        <TableCell align="center">
          {execution.completed ? (
            <span>{i18next.t("yes")}</span>
          ) : (
            <span>{i18next.t("no")}</span>
          )}
        </TableCell>

        <TableCell align="center">
          {execution.success ? (
            <span>{i18next.t("yes")}</span>
          ) : (
            <span>{i18next.t("no")}</span>
          )}
        </TableCell>

        <TableCell align="center">
          {Object.keys(execution.api_errors).length !== 0 ? (
            <span>{i18next.t("yes")}</span>
          ) : (
            <span>{i18next.t("no")}</span>
          )}
        </TableCell>
      </TableRow>
      {Object.keys(execution.api_errors).length !== 0 && (
        <TableRow key={`${execution.id}-api-errors`}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {execution.api_errors.general && (
                <Box sx={{ margin: 1 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Message</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {execution.api_errors.general.map((apiError, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={index}>
                          <TableCell>{apiError.error_message}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )}
              {execution.api_errors.purchase_orders && (
                <Box sx={{ margin: 1 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Property Ref</TableCell>
                        <TableCell>Severity</TableCell>
                        <TableCell>Target</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {execution.api_errors.purchase_orders.map((apiError) => (
                        <PurchaseOrderApiError apiError={apiError} />
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

type ServiceQuotationRowProps = {
  serviceQuotationId: number;
  lastExecution?: CreatePurchaseOrderByServiceQuotationExecution;
  tableRefreshCallback: Dispatch<SetStateAction<boolean>>;
};

const ServiceQuotationRow: FC<ServiceQuotationRowProps> = ({
  serviceQuotationId,
  lastExecution,
  tableRefreshCallback,
}): ReactElement => {
  const [serviceQuotation, setServiceQuotation] = useState<ServiceQuotation>();
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const columnQuantity = useMemo(() => 7, []);

  const handleFetchServiceQuotation = useCallback((id: number): void => {
    setLoading(() => true);
    getServiceQuotation(id)
      .then((data) => {
        setServiceQuotation(() => data);
      })
      .finally(() => {
        setLoading(() => false);
      });
  }, []);

  const handleRemoveServiceQuotation = useCallback(
    (id: number): void => {
      setLoadingRemove(() => true);
      removeCreatePurchaseOrderByServiceQuotation(id)
        .then(() => {
          tableRefreshCallback(() => true);
        })
        .finally(() => {
          setLoadingRemove(() => false);
          setShowConfirm(() => false);
        });
    },
    [tableRefreshCallback],
  );

  useEffect(() => {
    handleFetchServiceQuotation(serviceQuotationId);
  }, [serviceQuotationId, handleFetchServiceQuotation]);

  if (loading) {
    return (
      <TableRow key={serviceQuotationId}>
        {Array.from(Array(columnQuantity), () => (
          <TableCell>
            <Skeleton />
          </TableCell>
        ))}
      </TableRow>
    );
  }

  if (!serviceQuotation) {
    return (
      <TableRow key={serviceQuotationId}>
        <TableCell colSpan={columnQuantity}>Fail to load</TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow key={serviceQuotation.id}>
      <TableCell>{serviceQuotation.identifier}</TableCell>
      <TableCell>
        <span>
          {i18next.t("dateTime", {
            val: Date.parse(serviceQuotation.pr_item.requisition_date),
            interpolation: { escapeValue: false },
            formatParams: {
              val: {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                timeZone: "UTC",
                // hour: "numeric",
                // minute: "numeric",
                // second: "numeric",
                // hour12: false,
              },
            },
          })}
        </span>
      </TableCell>
      <TableCell>{serviceQuotation.pr_item.material?.matnumber}</TableCell>
      <TableCell>{serviceQuotation.vendor_winner?.fullvendorname}</TableCell>
      <TableCell>
        {serviceQuotation.purchase_order_item?.purchase_order.po_number}
      </TableCell>
      <TableCell>
        {serviceQuotation.purchase_order_item?.po_item_number}
      </TableCell>

      <TableCell align="right">
        {!serviceQuotation.purchase_order_item &&
          (showConfirm ? (
            <Tooltip title={i18next.t("confirm")}>
              <LoadingButton
                color="error"
                loading={loadingRemove}
                loadingPosition="center"
                onClick={() =>
                  handleRemoveServiceQuotation(serviceQuotation.id)
                }
              >
                {i18next.t("confirm")}
              </LoadingButton>
            </Tooltip>
          ) : (
            <Tooltip title={i18next.t("remove")}>
              <IconButton
                onClick={() => setShowConfirm(() => true)}
                disabled={lastExecution && !lastExecution.completed}
              >
                <Icon path={mdiTrashCan} size={0.8} />
              </IconButton>
            </Tooltip>
          ))}
        <Tooltip title={i18next.t("view")}>
          <IconButton
            component={Link}
            // to={`/vbuyer/quotations/${quotation.id}`}
            href={`/vbuyer/service_quotations/${serviceQuotation.id}`}
            target="_blank"
          >
            <Icon path={mdiEye} size={0.8} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const ShowCreatePurchaseOrderByCreatePurchaseOrderByServiceQuotation: FC =
  (): ReactElement => {
    // const createPurchaseOrderByServiceQuotation = useLoaderData() as CreatePurchaseOrderByServiceQuotation; # TODO: Future version of React Router Dom (RouterProvier)
    const { createPurchaseOrderByServiceQuotationId } = useParams();
    const [
      createPurchaseOrderByServiceQuotation,
      setCreatePurchaseOrderByServiceQuotation,
    ] = useState<CreatePurchaseOrderByServiceQuotation>();
    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tabOpen, setTabOpen] = useState("service_quotations");
    const [refresh, setRefresh] = useState(true);
    const [loadingNewExecution, setLoadingNewExecution] = useState(false);
    const [lastExecution, setLastExecution] = useState<
      undefined | CreatePurchaseOrderByServiceQuotationExecution
    >();

    const handleFetchCreatePurchaseOrderByServiceQuotation = useCallback(
      async (id: string) => {
        setLoading(() => true);
        await getCreatePurchaseOrderByServiceQuotation(id)
          .then((resource) => {
            const temp = resource;
            temp.create_purchase_order_by_service_quotation_executions =
              resource.create_purchase_order_by_service_quotation_executions.sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime(),
              );
            setCreatePurchaseOrderByServiceQuotation(() => resource);
          })
          .catch(() => {
            setNotFound(() => true);
          })
          .finally(() => {
            setRefresh(() => false);
            setLoading(() => false);
          });
      },
      [],
    );

    const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
      setTabOpen(() => newValue);
    };

    useEffect(() => {
      if (!refresh) {
        return;
      }

      if (!createPurchaseOrderByServiceQuotationId) {
        setNotFound(() => true);
        return;
      }

      handleFetchCreatePurchaseOrderByServiceQuotation(
        createPurchaseOrderByServiceQuotationId,
      );
    }, [
      handleFetchCreatePurchaseOrderByServiceQuotation,
      createPurchaseOrderByServiceQuotationId,
      refresh,
    ]);

    useEffect(() => {
      if (
        !createPurchaseOrderByServiceQuotation ||
        createPurchaseOrderByServiceQuotation
          .create_purchase_order_by_service_quotation_executions.length <= 0
      ) {
        return;
      }

      setLastExecution(
        () =>
          createPurchaseOrderByServiceQuotation
            .create_purchase_order_by_service_quotation_executions[0],
      );
    }, [createPurchaseOrderByServiceQuotation]);

    if (notFound || !createPurchaseOrderByServiceQuotationId) {
      return <NotFound />;
    }

    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 2,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <h1>{i18next.t("createPurchaseOrderByServiceQuotation.title")}</h1>

        {createPurchaseOrderByServiceQuotation &&
          !createPurchaseOrderByServiceQuotation.completed &&
          (loadingNewExecution ? (
            <Button variant="outlined" color="info">
              <CircularProgress
                color="success"
                size={16}
                sx={{ marginRight: "5px" }}
              />
              <span>
                {i18next.t("createPurchaseOrderByServiceQuotation.runAgain")}
              </span>
            </Button>
          ) : (
            <Button
              variant="outlined"
              type="button"
              href={`/vbuyer/api/v2/create_purchase_order_by_service_quotations/${createPurchaseOrderByServiceQuotation.id}/generate_purchase_order`}
              onClick={() => setLoadingNewExecution(() => true)}
              disabled={
                lastExecution &&
                (!lastExecution.completed ||
                  !createPurchaseOrderByServiceQuotation.can_retry)
              }
            >
              {i18next.t("createPurchaseOrderByServiceQuotation.runAgain")}
            </Button>
          ))}

        <TabContext value={tabOpen}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabOpen} onChange={handleTabChange}>
              <Tab
                label={i18next.t(
                  "createPurchaseOrderByServiceQuotation.service_quotations",
                )}
                value="service_quotations"
              />
              <Tab
                label={i18next.t(
                  "createPurchaseOrderByServiceQuotation.executions",
                )}
                value="executions"
              />
            </Tabs>
          </Box>

          <TabPanel value="service_quotations">
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        {i18next.t("serviceQuotation.identifier")}
                      </TableCell>
                      <TableCell align="left">
                        {i18next.t("serviceQuotation.requisitionDate")}
                      </TableCell>
                      <TableCell align="left">
                        {i18next.t("serviceQuotation.service")}
                      </TableCell>
                      <TableCell align="left">
                        {i18next.t("serviceQuotation.vendor")}
                      </TableCell>
                      <TableCell align="left">Purchase Order</TableCell>
                      <TableCell align="left">Purchase Order Item</TableCell>
                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && (
                      <TableRow key={1}>
                        {Array.from(Array(7), () => (
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                    {!loading &&
                      createPurchaseOrderByServiceQuotation &&
                      createPurchaseOrderByServiceQuotation.service_quotations
                        ?.length > 0 &&
                      createPurchaseOrderByServiceQuotation.service_quotations.map(
                        (serviceQuotation) => (
                          <ServiceQuotationRow
                            serviceQuotationId={serviceQuotation.id}
                            lastExecution={lastExecution}
                            tableRefreshCallback={setRefresh}
                          />
                        ),
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>

          <TabPanel value="executions">
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell align="left">
                        {i18next.t(
                          "createPurchaseOrderByServiceQuotationExecution.createdAt",
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {i18next.t(
                          "createPurchaseOrderByServiceQuotationExecution.updatedAt",
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {i18next.t(
                          "createPurchaseOrderByServiceQuotationExecution.completed",
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {i18next.t(
                          "createPurchaseOrderByServiceQuotationExecution.success",
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {i18next.t(
                          "createPurchaseOrderByServiceQuotationExecution.hasErrors",
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && (
                      <TableRow key={1}>
                        {Array.from(Array(6), () => (
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                    {!loading &&
                      createPurchaseOrderByServiceQuotation &&
                      createPurchaseOrderByServiceQuotation
                        .create_purchase_order_by_service_quotation_executions
                        ?.length > 0 &&
                      createPurchaseOrderByServiceQuotation.create_purchase_order_by_service_quotation_executions.map(
                        (execution) => (
                          <ExecutionRow
                            key={execution.id}
                            execution={execution}
                          />
                        ),
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    );
  };

export default ShowCreatePurchaseOrderByCreatePurchaseOrderByServiceQuotation;
