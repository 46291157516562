/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
import { mdiClipboardArrowDown } from "@mdi/js";
import { Icon } from "@mdi/react";
import { MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import { alpha, styled } from "@mui/material/styles";
import i18next from "i18next";
import { FC, MouseEvent, useState } from "react";
import InviteSuppliers from "../../molecules/InviteSuppliers";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface ActionProps {
  entityId?: number;
  options: string[];
}

const Actions: FC<ActionProps> = ({ entityId, options }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inviteSupplierOpen, setInviteSupplierOpen] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<Icon size={0.7} path={mdiClipboardArrowDown} />}
      >
        {i18next.t("actions")}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseActions}
      >
        {options.map((option, index) => (
          <div key={index}>
            {option === "invite_supplier" && entityId && (
              <MenuItem
                onClick={() => {
                  setInviteSupplierOpen(true);
                }}
                disableRipple
              >
                {i18next.t("serviceQuotation.button.invitationSupplier")}
              </MenuItem>
            )}
            {/* Adicionar mais opções ao menu de acordo com uma lista de opções -- PS.: Pode haver outra abordagem melhor */}
          </div>
        ))}
      </StyledMenu>
      {entityId && (
        <InviteSuppliers
          serviceQuotationId={entityId}
          handleCloseActions={handleCloseActions}
          open={inviteSupplierOpen}
          setOpen={setInviteSupplierOpen}
        />
      )}
    </div>
  );
};

export default Actions;
